import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function Index() {
  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          OTT services :
        </Typography>
        <ul>
          <li>User email catch based on user type =&gt; free or premium.</li>
        </ul>
      </Box>
    </Container>
  );
}
